import React, { FunctionComponent } from "react";

import { Typography } from "antd";
import SEO from "../components/Seo";
import { DefaultLayout } from "../components/DefaultLayout";
import { ContentCard } from "../components/ContentCard";
import { colors } from "../theme/variables";
import styled from "@emotion/styled";

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${colors.blue.primary};
  }
`;

const Datenschutz: FunctionComponent = () => {
  return (
    <>
      <SEO title={"Datenschutz"} />
      <DefaultLayout>
        <ContentCard
          title={<StyledTitle level={2}>Datenschutzerklärung</StyledTitle>}
        >
          <StyledTitle level={4}>1. Datenschutz auf einen Blick</StyledTitle>
          <StyledTitle level={4}>Allgemeine Hinweise</StyledTitle>
          <Typography.Paragraph>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            Datenerfassung auf unserer Website
          </StyledTitle>
          <Typography.Paragraph>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </Typography.Paragraph>
          <Typography.Paragraph>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
            <Typography.Paragraph>
              Wie erfassen wir Ihre Daten?
            </Typography.Paragraph>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. Andere Daten werden automatisch beim
            Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie unsere Website betreten. Wofür nutzen wir
            Ihre Daten? Ein Teil der Daten wird erhoben, um eine fehlerfreie
            Bereitstellung der Website zu gewährleisten. Andere Daten können zur
            Analyse Ihres Nutzerverhaltens verwendet werden. Welche Rechte haben
            Sie bezüglich Ihrer Daten? Sie haben jederzeit das Recht
            unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
            gespeicherten personenbezogenen Daten zu erhalten. Sie haben
            außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser
            Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
            Datenschutz können Sie sich jederzeit unter der im Impressum
            angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Außerdem
            haben Sie das Recht, unter bestimmten Umständen die Einschränkung
            der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Details
            hierzu entnehmen Sie der Datenschutzerklärung unter „Recht auf
            Einschränkung der Verarbeitung“.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Wofür nutzen wir Ihre Daten?
          </Typography.Paragraph>
          <Typography.Paragraph>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </Typography.Paragraph>
          <Typography.Paragraph>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            Außerdem haben Sie das Recht, unter bestimmten Umständen die
            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung
            unter „Recht auf Einschränkung der Verarbeitung“.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            2. Allgemeine Hinweise und Pflichtinformationen
          </StyledTitle>
          <StyledTitle level={4}>Datenschutz</StyledTitle>
          <Typography.Paragraph>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z. B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            Hinweis zur verantwortlichen Stelle
          </StyledTitle>
          <Typography.Paragraph>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </Typography.Paragraph>
          <StyledTitle level={4}>Anschrift</StyledTitle>
          <Typography.Paragraph>
            horst ehlers architekt (baugewerblich)
          </Typography.Paragraph>
          <Typography.Paragraph>
            Rudolf-Hilferding-Straße 74, 60439 Frankfurt am Main
          </Typography.Paragraph>
          <Typography.Paragraph>
            Tel. +49 (0)69 65 00 86 91
          </Typography.Paragraph>
          <Typography.Paragraph>
            Fax: +49 (0)69 65 00 86 92
          </Typography.Paragraph>
          <Typography.Paragraph>
            Email: mail@architekt-horst-ehlers.de
          </Typography.Paragraph>
          <Typography.Paragraph>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z. B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            Widerruf ihrer Einwilligung zur Datenverarbeitung
          </StyledTitle>
          <Typography.Paragraph>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (art. 21 dsgvo)
          </StyledTitle>
          <Typography.Paragraph>
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
            oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen,
            die sich aus Ihrer besonderen Situation ergeben, gegen die
            Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen;
            dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
            entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch
            einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht
            mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige
            Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
            und Freiheiten überwiegen oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            (Widerspruch nach Art. 21 Abs. 1 DSGVO). Werden Ihre
            personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben,
            so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung
            Sie betreffender personenbezogener Daten zum Zwecke derartiger
            Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit
            solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen,
            werden Ihre personenbezogenen Daten anschließend nicht mehr zum
            Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2
            DSGVO).
          </Typography.Paragraph>
          <StyledTitle level={4}>
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </StyledTitle>
          <Typography.Paragraph>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </Typography.Paragraph>
          <StyledTitle level={4}>Recht auf Datenübertragbarkeit</StyledTitle>
          <Typography.Paragraph>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </Typography.Paragraph>
          <StyledTitle level={4}>SSL- bzw. TLS-Verschlüsselung</StyledTitle>
          <Typography.Paragraph>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            Auskunft, Sperrung, Löschung und Berichtigung
          </StyledTitle>
          <Typography.Paragraph>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            Recht auf Einschränkung der Verarbeitung
          </StyledTitle>
          <Typography.Paragraph>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen: Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
            um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen. Wenn die Verarbeitung Ihrer personenbezogenen
            Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung
            die Einschränkung der Datenverarbeitung verlangen. Wenn wir Ihre
            personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
            Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
            benötigen, haben Sie das Recht, statt der Löschung die Einschränkung
            der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn
            Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
            muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
            werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
            haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Wenn Sie die Verarbeitung
            Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese
            Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung
            oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen
            natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Europäischen Union oder eines
            Mitgliedstaats verarbeitet werden.
          </Typography.Paragraph>
          <StyledTitle level={4}>Widerspruch gegen Werbe-W-mails</StyledTitle>
          <Typography.Paragraph>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            3. Datenerfassung auf unserer Website
          </StyledTitle>
          <StyledTitle level={4}>Cookies</StyledTitle>
          <Typography.Paragraph>
            Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
            richten auf Ihrem Rechner keinen Schaden an und enthalten keine
            Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
            Die meisten der von uns verwendeten Cookies sind so genannte
            „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so
            einstellen, dass Sie über das Setzen von Cookies informiert werden
            und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
            bestimmte Fälle oder generell ausschließen sowie das automatische
            Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
            Deaktivierung von Cookies kann die Funktionalität dieser Website
            eingeschränkt sein. Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z. B. Warenkorbfunktion) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Soweit andere Cookies (z. B. Cookies
            zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese
            in dieser Datenschutzerklärung gesondert behandelt.
          </Typography.Paragraph>
          <StyledTitle level={4}>Server-Log-Dateien</StyledTitle>
          <Typography.Paragraph>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind: Browsertyp und
            Browserversion verwendetes Betriebssystem Referrer URL Hostname des
            zugreifenden Rechners Uhrzeit der Serveranfrage IP-Adresse Eine
            Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
            vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der technisch fehlerfreien Darstellung und
            der Optimierung seiner Website – hierzu müssen die Server-Log-Files
            erfasst werden.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            Anfrage per E-mail, Telefon oder Telefax
          </StyledTitle>
          <Typography.Paragraph>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf
            Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen
            beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO) und/oder auf unseren berechtigten Interessen (Art. 6 Abs. 1
            lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven
            Bearbeitung der an uns gerichteten Anfragen haben. Die von Ihnen an
            uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis
            Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B.
            nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
            gesetzliche Bestimmungen – insbesondere gesetzliche
            Aufbewahrungsfristen – bleiben unberührt.
          </Typography.Paragraph>
        </ContentCard>
      </DefaultLayout>
    </>
  );
};

export default Datenschutz;
